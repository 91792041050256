import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import IconButton from '@mui/material/IconButton'

const HideRevealToggleButton = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <IconButton
            sx={{
                padding: `0px`,
                height: `5px`,
            }}
            disableRipple
        >
            <KeyboardArrowDownIcon
                sx={{
                    color: `Black`,
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .2s cubic-bezier(0.4, 0, 1, 1)',
                    cursor: {
                        xs: `pointer`,
                        sm: `pointer`,
                        md: `default`,
                        lg: `default`,
                        xl: `default`,
                    },
                }}
            />
        </IconButton>
    )
}

export default HideRevealToggleButton
