import Text from '@mui/material/Typography'
import Link from 'next/link'

import NavItemWrapper from '@/sections/NavBar/NavItemWrapper'

const NavItem = ({
    to,
    text,
    onClick,
    forceHandCursorOnHover,
}: {
    to: string
    text: string
    onClick?: () => void
    forceHandCursorOnHover?: boolean
}) => (
    <Link
        href={to}
        style={{
            textDecoration: 'none',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            lineHeight: 0,
        }}
        onClick={onClick}
    >
        <NavItemWrapper forceHandCursorOnHover={forceHandCursorOnHover}>
            <Text
                variant="button"
                color={`Grey900`}
                textAlign={`left`}
                whiteSpace={`nowrap`}
                lineHeight={0}
            >
                {text}
            </Text>
        </NavItemWrapper>
    </Link>
)

export default NavItem
