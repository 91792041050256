import Bugsnag from '@bugsnag/js'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { Component, ReactNode } from 'react'

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error) {
        if (process.env.NEXT_PUBLIC_BUGSNAG_APIKEY) {
            Bugsnag.notify(error)
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Box
                    sx={{
                        width: `100%`,
                        height: `100%`,
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        flexDirection: 'column',
                    }}
                >
                    <Text variant="h5" color={LuneTheme.palette.Red500}>
                        Really sorry! Something went wrong.
                    </Text>
                    <Text color={LuneTheme.palette.Black}>
                        We have been notified and are investigating the issue.
                    </Text>
                </Box>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
