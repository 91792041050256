import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'

const StandardLayout = ({ sx, children, ...rest }: { sx?: SxProps; children: React.ReactNode }) => {
    return (
        <Box
            {...rest}
            px={{
                xs: 3,
                sm: 6,
                md: 6,
                lg: 15,
                xl: 15,
            }}
            sx={{
                position: 'relative',
                height: `100%`,
                ...(sx ?? {}),
            }}
        >
            {children}
        </Box>
    )
}

export default StandardLayout
