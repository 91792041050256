import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'

import NavItemWrapper from '@/sections/NavBar/NavItemWrapper'

const LoginBtn = () => (
    <Box
        sx={{
            mx: {
                xs: 0,
                sm: 2.5,
                md: 2.5,
                lg: 2.5,
                xl: 2.5,
            },
        }}
    >
        <a
            style={{
                textDecoration: `none`,
            }}
            href={`https://dashboard.lune.co/`}
        >
            <NavItemWrapper>
                <Text variant={`button`} color={`Grey900`} whiteSpace={`nowrap`}>
                    Log In
                </Text>
            </NavItemWrapper>
        </a>
    </Box>
)

export default LoginBtn
