import { Button, LuneTheme } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const CTAButton = ({
    text,
    href,
    onClick,
}: {
    text: string
    href?: string
    onClick?: () => void
}) => {
    const { palette } = LuneTheme

    return (
        <Button
            variant="contained"
            href={href}
            onClick={onClick}
            sx={{
                width: {
                    xs: `100%`,
                    sm: `max-content`,
                    md: `max-content`,
                    lg: `max-content`,
                    xl: `max-content`,
                },
            }}
            leftIcon={
                <ArrowCircleRightIcon
                    sx={{
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        width: `20px`,
                        height: `20px`,
                        borderRadius: `40px`,
                        backgroundColor: palette.Grey900,
                        color: palette.White,
                    }}
                />
            }
        >
            {text}
        </Button>
    )
}

export default CTAButton
