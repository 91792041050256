import Divider from '@mui/material/Divider'

import DropDownMenu from '@/sections/NavBar/DropDownMenu'
import { ProductAPIMenu, ProductPlatformMenu } from '@/sections/NavBar/ProductMenu'

const ProductDropDownMenu = () => {
    return (
        <DropDownMenu header={'Product'}>
            <ProductAPIMenu />
            <Divider />
            <ProductPlatformMenu />
        </DropDownMenu>
    )
}

export default ProductDropDownMenu
