'use client'

import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useMemo } from 'react'

import SitewideBanner from '@/components/SitewideBanner'
import StandardLayout from '@/components/StandardLayout'
import { useWindowScroll } from '@/hooks/useWindowScroll'
import DesktopNav from '@/sections/NavBar/DesktopNav'
import MobileNav from '@/sections/NavBar/MobileNav'
import { RenderOn } from '@/utills/DeviceUtils'
import { BannerData } from '@/utills/SitewideBanner'

export const desktopNavItemsMxSpacing = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
}

const NavBar = ({
    banner,
    backgroundColor,
    onBackgroundChange,
}: {
    banner: BannerData
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = LuneTheme
    const scrollPosition = useWindowScroll()
    const setWhiteBg = scrollPosition > 30

    const navBarStyle = useMemo(() => {
        return {
            paddingLeft: '0px',
            paddingRight: '0px',
            height: '72px',
            backgroundColor: setWhiteBg ? 'white' : 'transparent',
            transition: 'background-color .4s ease-in-out',
            display: 'flex',
            alignItems: 'space-between',
            width: '100%',
        }
    }, [setWhiteBg])

    return (
        <Box sx={{ position: 'sticky', zIndex: 9, top: 0, width: '100%' }}>
            <SitewideBanner banner={banner}></SitewideBanner>
            <Box sx={navBarStyle}>
                <Container
                    maxWidth="xl"
                    sx={{
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important',
                    }}
                >
                    <StandardLayout sx={{ width: '100%' }}>
                        <RenderOn breakPoints={['sm', 'xs']}>
                            <MobileNav />
                        </RenderOn>
                        <RenderOn breakPoints={['xl', 'lg', 'md']}>
                            <DesktopNav
                                backgroundColor={backgroundColor ?? palette.White}
                                onBackgroundChange={onBackgroundChange}
                            />
                        </RenderOn>
                    </StandardLayout>
                </Container>
            </Box>
        </Box>
    )
}

export default NavBar
