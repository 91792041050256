import Image from 'next/image'
import { FC } from 'react'

import { GridMenuGroup, MobileMenuGroup, StackMenuGroup } from '@/sections/NavBar/MenuGroup'

const business = [
    {
        url: '/fund-climate-projects',
        label: 'Buy carbon credits',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-carbon.png`}
                alt={`Carbon buyers`}
            />
        ),
    },
]

const customers = [
    {
        url: '/logistics',
        label: 'Logistics',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-logistics.png`}
                alt={`Logistics`}
            />
        ),
    },
    {
        url: '/procurement',
        label: 'Procurement',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-procurement.png`}
                alt={`Procurement`}
            />
        ),
    },
    {
        url: '/spend-management',
        label: 'Spend management',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-fintech.png`}
                alt={`Spend management`}
            />
        ),
    },
]

export const SolutionsBusinessMenu: FC = () => {
    return <StackMenuGroup header={`For your business`} items={business} width={400} />
}

export const SolutionsCustomersMenu: FC = () => {
    return <GridMenuGroup header={`For your customers`} items={customers} width={550} />
}

export const SolutionsMobileMenu: FC<{ onClose: () => void }> = ({ onClose }) => {
    return <MobileMenuGroup items={[...business, ...customers]} onClose={onClose} />
}
