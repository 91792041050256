import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Text from '@mui/material/Typography'
import React, { FC, useState } from 'react'
import { useDebounce } from 'use-debounce'

import HideRevealToggleButton from '@/components/HideRevealToggleButton'
import NavItemWrapper from '@/sections/NavBar/NavItemWrapper'

const DropDownMenu: FC<{ header: string; children: React.ReactNode }> = ({ header, children }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [openDebounced] = useDebounce(isOpen, 100)

    const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Box
                style={{
                    textDecoration: 'none',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    lineHeight: 0,
                    color: `#212121`,
                }}
            >
                <NavItemWrapper
                    onMouseOver={handleHover}
                    onMouseLeave={handleClose}
                    id={'basic-menu'}
                    isOpen={isOpen}
                >
                    <div
                        style={{
                            textDecoration: 'none',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                            marginRight: `-6px`,
                        }}
                    >
                        <Text variant={`button`} color={`Gray900`}>
                            {header}
                        </Text>
                        <HideRevealToggleButton isOpen={openDebounced} />
                    </div>
                </NavItemWrapper>
            </Box>
            {anchorEl && (
                <Popper
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={true}
                    onClick={handleClose}
                    onMouseOver={() => setIsOpen(true)}
                    onMouseLeave={handleClose}
                    keepMounted
                    style={{
                        zIndex: isOpen ? '9999' : '0',
                        transition: 'zIndex .3s cubic-bezier(0.4, 0, 1, 1)',
                        pointerEvents: isOpen ? 'all' : 'none',
                    }}
                >
                    <Box
                        className={`${openDebounced ? 'menuOpened' : ''}`}
                        sx={{
                            transition: 'all .3s cubic-bezier(0.4, 0, 1, 1)',
                            animation: 'show .3s cubic-bezier(0.4, 0, 1, 1)',
                            mt: 2,
                            opacity: 0,
                            zIndex: -1,
                            '&.menuOpened': {
                                mt: 3,
                                opacity: 1,
                                zIndex: 999,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: `16px`,
                                padding: 0,
                                backgroundColor: `White`,
                                boxShadow: 16,
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </Popper>
            )}
        </>
    )
}

export default DropDownMenu
