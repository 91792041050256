import Image from 'next/image'
import { FC } from 'react'

import { MobileMenuGroup, StackMenuGroup } from '@/sections/NavBar/MenuGroup'

const resources = [
    {
        url: '/resource-hub',
        label: 'Resource hub',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-content-hub.png`}
                alt={`Resource hub`}
            />
        ),
    },
    {
        url: '/customers',
        label: 'Customer stories',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-customer-stories.png`}
                alt={`API docs`}
            />
        ),
    },
    {
        url: 'https://docs.lune.co',
        label: 'API docs',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-documentation.png`}
                alt={`API docs`}
            />
        ),
    },
]

const guides = [
    {
        url: '/scope-3-business-travel-emissions-guide',
        label: 'Measuring scope 3 business travel emissions',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-measure-travel-emissions.png`}
                alt={`Measuring scope 3 business travel emissions`}
            />
        ),
    },
    {
        url: '/greening-the-supply-chain',
        label: 'Greening the supply chain',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-greening-supply-chain.png`}
                alt={`Greening the supply chain`}
            />
        ),
    },
    {
        url: '/business-spend',
        label: 'Planet and profit: The secret advantage of business spend',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/stamp-business-spend.png`}
                alt={`Planet and profit: The secret advantage of business spend`}
            />
        ),
    },
    {
        url: '/lune-climate-project-evaluation-guide',
        newPage: false, // I actually am confused, so let's make it easy to change.
        label: 'Quality over quantity: how we evaluate carbon projects at Lune',
        img: (
            <Image
                width={87}
                height={60}
                src={`/illustrations/menu-tile-images/quality-over-quantity.png`}
                alt={`Quality over quantity: how we evaluate carbon projects at Lune`}
            />
        ),
    },
]

export const ResourcesMenu: FC = () => {
    return <StackMenuGroup items={resources} width={400} />
}

export const ResourcesGuidesMenu: FC = () => {
    return <StackMenuGroup items={guides} width={400} />
}

export const ResourcesMobileMenu: FC<{ onClose: () => void }> = ({ onClose }) => {
    return <MobileMenuGroup items={[...resources, ...guides]} onClose={onClose} />
}
