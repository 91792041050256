'use client'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import createCache, { Options } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as LuneThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/system'
import { useServerInsertedHTML } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'

import ErrorBoundary from '@/app/ErrorBoundary'

export const themeWithNewBreakpoints = createTheme({
    breakpoints: {
        // The Website Refresh uses a different set of breakpoints
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
    },
})

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: { options: Options; children: ReactNode }) {
    const { options, children } = props

    const [{ cache, flush }] = useState(() => {
        const cache = createCache(options)
        cache.compat = true
        const prevInsert = cache.insert
        let inserted: string[] = []
        cache.insert = (...args) => {
            const serialized = args[1]
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (cache.inserted[serialized.name] === undefined) {
                inserted.push(serialized.name)
            }
            return prevInsert(...args)
        }
        const flush = () => {
            const prevInserted = inserted
            inserted = []
            return prevInserted
        }
        return { cache, flush }
    })

    useServerInsertedHTML(() => {
        const names = flush()
        if (names.length === 0) {
            return null
        }
        let styles = ''
        for (const name of names) {
            styles += cache.inserted[name]
        }
        return (
            <style
                key={cache.key}
                data-emotion={`${cache.key} ${names.join(' ')}`}
                dangerouslySetInnerHTML={{
                    __html: styles,
                }}
            />
        )
    })

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_BUGSNAG_APIKEY) {
            Bugsnag.start({
                apiKey: process.env.NEXT_PUBLIC_BUGSNAG_APIKEY,
                plugins: [new BugsnagPluginReact()],
            })
        }
    }, [])

    return (
        <ErrorBoundary>
            <CacheProvider value={cache}>
                <LuneThemeProvider
                    theme={{
                        ...LuneTheme,
                        breakpoints: themeWithNewBreakpoints.breakpoints,
                    }}
                >
                    <CssBaseline />
                    {children}
                </LuneThemeProvider>
            </CacheProvider>
        </ErrorBoundary>
    )
}
