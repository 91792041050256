import { CtaTile } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import Link from 'next/link'
import React, { FC } from 'react'

const isExternalOrBlog = (link: string): boolean =>
    link.startsWith('https://') || link.startsWith('http://')

type MenuGroupProps = {
    header?: string
    width: number
    items: { url: string; label: string; img: React.ReactNode; newPage?: boolean }[] // newPage, when set, overrules isExternalOrBlog
}

const getTargetProp = (item: { url: string; newPage?: boolean }) => {
    if (item.newPage !== undefined) {
        return item.newPage ? '_blank' : undefined
    }
    return isExternalOrBlog(item.url) ? '_blank' : undefined
}

export const GridMenuGroup: FC<MenuGroupProps> = ({ header, items, width }) => {
    return (
        <Stack sx={{ padding: '24px 32px', width: `${width}px` }} spacing={4}>
            <Box>
                {header && (
                    <Text
                        variant={`overline`}
                        color={`Grey600`}
                        sx={{
                            display: 'block',
                            pt: '10px',
                            pb: '10px',
                            mb: 1,
                        }}
                    >
                        {header}
                    </Text>
                )}
                <Box
                    sx={{
                        display: 'grid',
                        gap: '8px',
                        gridAutoFlow: 'column',
                        gridTemplateRows: '50% 50%',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    {items.map((item) => (
                        <Link
                            key={`menu-${item.label}`}
                            href={item.url}
                            style={{ textDecoration: 'none' }}
                            target={getTargetProp(item)}
                            rel={getTargetProp(item) === '_blank' ? 'noreferrer' : undefined}
                        >
                            <CtaTile
                                mini
                                sxProps={{
                                    pr: 1,
                                    '> .MuiBox-root >.MuiBox-root:first-of-type': {
                                        minWidth: '87px',
                                        img: {
                                            height: '100%',
                                        },
                                    },
                                }}
                                showArrow={false}
                                image={item.img}
                                title={item.label}
                            />
                        </Link>
                    ))}
                </Box>
            </Box>
        </Stack>
    )
}

export const StackMenuGroup: FC<MenuGroupProps> = ({ header, width, items }) => {
    return (
        <Stack sx={{ padding: '24px 32px', width: `${width}px` }} spacing={4}>
            <Box>
                {header && (
                    <Text
                        variant={`overline`}
                        color={`Grey600`}
                        sx={{
                            display: 'block',
                            pt: '10px',
                            pb: '10px',
                            mb: 1,
                        }}
                    >
                        {header}
                    </Text>
                )}
                <Stack spacing={1}>
                    {items.map((item) => (
                        <Link
                            key={`menu-${item.label}`}
                            href={item.url}
                            style={{ textDecoration: 'none' }}
                            target={getTargetProp(item)}
                        >
                            <CtaTile
                                mini
                                sxProps={{
                                    pr: 1,
                                    '> .MuiBox-root >.MuiBox-root:first-of-type': {
                                        minWidth: '87px',
                                        img: {
                                            height: '100%',
                                        },
                                    },
                                }}
                                showArrow={false}
                                image={item.img}
                                title={item.label}
                            />
                        </Link>
                    ))}
                </Stack>
            </Box>
        </Stack>
    )
}

export const MobileMenuGroup: FC<{ items: MenuGroupProps['items']; onClose: () => void }> = ({
    items,
    onClose,
}) => {
    return (
        <>
            {items.map((item) => (
                <Link
                    key={`mobile-menu-${item.label}`}
                    href={item.url}
                    style={{ textDecoration: 'none' }}
                    onClick={onClose}
                    target={getTargetProp(item)}
                >
                    <Box
                        key={`mobile-menu-${item.label}`}
                        sx={{
                            margin: '8px 16px',
                        }}
                    >
                        <CtaTile
                            mini
                            showArrow={false}
                            image={item.img}
                            title={item.label}
                            sxProps={{
                                '> .MuiBox-root >.MuiBox-root:first-of-type': {
                                    minWidth: '87px',
                                    img: {
                                        height: '100%',
                                    },
                                },
                            }}
                        />
                    </Box>
                </Link>
            ))}
        </>
    )
}
