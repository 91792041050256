'use client'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { useCallback, useEffect, useRef } from 'react'

import CTAButton from '@/components/CTAButton'
import Logo from '@/components/Logo'
import LoginBtn from '@/sections/NavBar/LoginButton'
import NavItem from '@/sections/NavBar/NavItem'
import ProductDropDownMenu from '@/sections/NavBar/ProductDropDownMenu'
import ResourcesDropDownMenu from '@/sections/NavBar/ResourcesDropDownMenu'
import SolutionsDropDownMenu from '@/sections/NavBar/SolutionsDropDownMenu'

const DesktopNavBar = ({
    backgroundColor: initialBackgroundColor,
    onBackgroundChange,
}: {
    backgroundColor?: string
    onBackgroundChange?: (color: string) => void
}) => {
    const { palette } = LuneTheme
    const ref = useRef<HTMLDivElement>(null)

    const changeBackground = useCallback(() => {
        const navHeight = ref.current?.clientHeight ?? 0
        const color =
            window.scrollY > navHeight ? palette.White : (initialBackgroundColor ?? palette.White)

        if (onBackgroundChange && color) {
            onBackgroundChange(color)
        }
    }, [initialBackgroundColor, onBackgroundChange, palette.White])

    if (initialBackgroundColor !== palette.White) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            window.addEventListener('scroll', changeBackground)
            return () => window.removeEventListener('scroll', changeBackground)
        }, [changeBackground])
    }

    return (
        <Box
            ref={ref}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                width: '100%',
            }}
        >
            <Box ref={ref} display={`flex`} alignItems={`center`}>
                <Box
                    width={104}
                    height={32}
                    sx={{
                        mr: {
                            xs: 0,
                            sm: 1,
                            md: 2.5,
                            lg: 2.5,
                            xl: 2.5,
                        },
                        display: {
                            xs: `initial`,
                            sm: `none`,
                            md: `initial`,
                            lg: `initial`,
                            xl: `initial`,
                        },
                    }}
                >
                    <Logo />
                </Box>
                <ProductDropDownMenu />
                <SolutionsDropDownMenu />
                <ResourcesDropDownMenu />
                <NavItem to={`/about/`} text={`About`} forceHandCursorOnHover={true} />
            </Box>
            <Box display={`flex`} alignItems={`center`}>
                <LoginBtn />
                <CTAButton
                    text={`Request a demo`}
                    href="/request-a-demo"
                    onClick={() => {
                        /* @ts-ignore: ... */
                        gtag('event', 'request_demo', {})
                    }}
                />
            </Box>
        </Box>
    )
}

export default DesktopNavBar
