'use client'
import { fontFamily } from '@lune-fe/lune-ui-lib'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { RenderOn } from '@/utills/DeviceUtils'
import { BannerData } from '@/utills/SitewideBanner'

const SITEWIDE_COOKIE_NAME = 'sitewide_banner'
const cookies = new Cookies(null, { path: '/' })

export default function TopBanner({ banner }: { banner: BannerData }) {
    const [disabled, setDisabled] = useState<boolean>(true)

    useEffect(() => {
        const sitewideBannerCookie = cookies.get(SITEWIDE_COOKIE_NAME)
        const sitewideBannerDisabledTime = sitewideBannerCookie
            ? moment(sitewideBannerCookie)
            : undefined
        setDisabled(
            !!(
                banner === undefined ||
                (sitewideBannerDisabledTime && sitewideBannerDisabledTime.isAfter(banner.startTime))
            ),
        )
    }, [banner])

    const onBannerClose = () => {
        cookies.set(SITEWIDE_COOKIE_NAME, moment.utc().toISOString())
        setDisabled(true)
    }

    const bannerContent = (horizontalPadding: number) => (
        <div
            className="sitewidebanner"
            style={{
                display: 'flex',
                background: '#FFF1A7',
                width: '100%',
                height: '48px',
                gap: '10px',
                left: '0px',
                top: '0px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    maxWidth: `1536px`,
                    padding: `12px ${horizontalPadding}px 12px ${horizontalPadding}px`,
                    width: '100%',
                }}
            >
                <a
                    href={banner && banner.link}
                    style={{
                        width: '100%',
                        color: '#212121',
                        fontFamily,
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '17px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                    onClick={onBannerClose}
                >
                    {banner && banner.title}
                </a>
                <div
                    style={{
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        margin: 'auto',
                    }}
                >
                    <button
                        onClick={onBannerClose}
                        style={{
                            background: 'transparent',
                            border: 'none',
                            outline: 'none',
                            padding: '0px',
                            width: '100%',
                            height: '100%',
                            fontSize: '20px',
                            cursor: 'pointer',
                        }}
                    >
                        ✕
                    </button>
                </div>
            </div>
        </div>
    )

    return disabled ? (
        <></>
    ) : (
        <>
            <RenderOn breakPoints={['xs']}>{bannerContent(24)}</RenderOn>
            <RenderOn breakPoints={['sm', 'md']}>{bannerContent(48)}</RenderOn>
            <RenderOn breakPoints={['xl', 'lg']}>{bannerContent(120)}</RenderOn>
        </>
    )
}
