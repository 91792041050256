import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import React from 'react'

import { desktopNavItemsMxSpacing } from '@/sections/NavBar/NavBar'

const NavItemWrapper = ({
    children,
    onMouseOver,
    onMouseLeave,
    isOpen,
    id,
    forceHandCursorOnHover,
}: {
    children: React.ReactNode
    onMouseOver?: React.MouseEventHandler
    onMouseLeave?: React.MouseEventHandler
    isOpen?: boolean
    id?: string
    forceHandCursorOnHover?: boolean
}) => {
    const { palette } = LuneTheme
    return (
        <Box
            id={id || `item`}
            sx={{
                mx: desktopNavItemsMxSpacing,
                mb: 0,
                p: 2,
                py: {
                    xs: 2,
                    sm: 2.5,
                    md: 2.5,
                    lg: 2.5,
                    xl: 2.5,
                },
                backgroundColor: isOpen ? palette.Grey200 : `transparent`,
                '&:hover': {
                    backgroundColor: palette.Grey200,
                },
                borderRadius: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: {
                    xs: `pointer`,
                    sm: `pointer`,
                    md: forceHandCursorOnHover ? `pointer` : `default`,
                    lg: forceHandCursorOnHover ? `pointer` : `default`,
                    xl: forceHandCursorOnHover ? `pointer` : `default`,
                },
            }}
            height={22}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            component="div"
        >
            {children}
        </Box>
    )
}

export default NavItemWrapper
