import Divider from '@mui/material/Divider'

import DropDownMenu from '@/sections/NavBar/DropDownMenu'
import { ResourcesGuidesMenu, ResourcesMenu } from '@/sections/NavBar/ResourcesMenu'

const ResourcesDropDownMenu = () => {
    return (
        <DropDownMenu header={'Resources'}>
            <ResourcesMenu />
            <Divider />
            <ResourcesGuidesMenu />
        </DropDownMenu>
    )
}

export default ResourcesDropDownMenu
