import Divider from '@mui/material/Divider'

import DropDownMenu from '@/sections/NavBar/DropDownMenu'
import { SolutionsBusinessMenu, SolutionsCustomersMenu } from '@/sections/NavBar/SolutionsMenu'

const SolutionsDropDownMenu = () => {
    return (
        <DropDownMenu header={'Solutions'}>
            <SolutionsBusinessMenu />
            <Divider />
            <SolutionsCustomersMenu />
        </DropDownMenu>
    )
}

export default SolutionsDropDownMenu
