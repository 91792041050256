import { Button, LuneTheme } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import type { SxProps } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Text from '@mui/material/Typography'
import { FC, useState } from 'react'

import HideRevealToggleButton from '@/components/HideRevealToggleButton'
import Logo from '@/components/Logo'
import StandardLayout from '@/components/StandardLayout'
import LoginBtn from '@/sections/NavBar/LoginButton'
import NavItem from '@/sections/NavBar/NavItem'
import NavItemWrapper from '@/sections/NavBar/NavItemWrapper'
import { ProductMobileMenu } from '@/sections/NavBar/ProductMenu'
import { ResourcesMobileMenu } from '@/sections/NavBar/ResourcesMenu'
import { SolutionsMobileMenu } from '@/sections/NavBar/SolutionsMenu'

const MENU_ITEM_HEIGHT = '64px'

const MobileMenuItem: FC<{
    children: React.ReactNode
    sx?: SxProps
    removeBorder?: boolean
    subMenu?: React.ReactNode
}> = ({ children, removeBorder, sx, subMenu }) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const { palette } = LuneTheme
    return (
        <Box
            onClick={() => {
                if (subMenu) {
                    setExpanded(!expanded)
                }
            }}
            sx={{
                position: 'sticky',
                width: '100%',
                borderBottom: removeBorder ? 'none' : `1px solid ${palette.Grey300}`,
                borderColor: `Grey300`,
                transition: 'all .3s cubic-bezier(0.4, 0, 1, 1)',
                maxHeight: expanded ? '500px' : MENU_ITEM_HEIGHT,
                overflow: 'hidden',
                pb: expanded ? 1 : 0,
                flex: '1 0 auto',
                ...(sx && { ...sx }),
            }}
        >
            <Box
                sx={{
                    height: MENU_ITEM_HEIGHT,
                    width: '100%',
                    alignItems: 'stretch',
                    display: 'flex',
                    pl: 1,
                    pr: 1,
                    a: {
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        display: 'flex',
                    },
                }}
            >
                <Box sx={{ width: '100%', alignItems: 'center', display: 'flex' }}>{children}</Box>
                {!!subMenu && (
                    <Box
                        sx={{
                            padding: '0 16px',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <HideRevealToggleButton isOpen={expanded} />
                    </Box>
                )}
            </Box>
            {!!subMenu && <> {subMenu}</>}
        </Box>
    )
}

export default function PersistentDrawerLeft() {
    const [open, setOpen] = useState<boolean>(false)
    const { palette } = LuneTheme

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="absolute"
                sx={{
                    boxShadow: 'none',
                    backgroundColor: open ? `${palette.White}` : 'transparent',
                    color: palette.Black,
                    height: '72px',
                }}
            >
                <StandardLayout>
                    <Box
                        display={`flex`}
                        justifyContent={`space-between`}
                        alignItems={`center`}
                        height={`72px`}
                    >
                        <Box height={`32px`} width={`104px`}>
                            <Logo logoClicked={() => handleDrawerClose()} />
                        </Box>
                        {open ? (
                            <IconButton onClick={handleDrawerClose} sx={{ mr: -1 }}>
                                <CloseIcon sx={{ color: `Grey900` }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                sx={{ mr: -1 }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                </StandardLayout>
            </AppBar>
            {open && (
                <Drawer
                    sx={{
                        flexShrink: 0,
                        left: '0px',
                        right: '0px',
                        position: 'absolute',
                        '&.MuiDrawer-paper': {
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="top"
                    open={open}
                    PaperProps={{
                        sx: {
                            position: 'sticky',
                            width: '100%',
                            marginTop: '64px',
                            maxHeight: 'calc(100% - 64px)',
                            boxShadow:
                                '0px 2px 4px -4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px -10px rgba(0, 0, 0, 0.12)',
                        },
                    }}
                    SlideProps={{
                        timeout: 0,
                    }}
                >
                    <MobileMenuItem subMenu={<ProductMobileMenu onClose={handleDrawerClose} />}>
                        <NavItemWrapper>
                            <Text
                                variant="button"
                                color={`Grey900`}
                                textAlign={`left`}
                                whiteSpace={`nowrap`}
                                lineHeight={0}
                            >
                                Product
                            </Text>
                        </NavItemWrapper>
                    </MobileMenuItem>
                    <MobileMenuItem subMenu={<SolutionsMobileMenu onClose={handleDrawerClose} />}>
                        <NavItemWrapper>
                            <Text
                                variant="button"
                                color={`Grey900`}
                                textAlign={`left`}
                                whiteSpace={`nowrap`}
                                lineHeight={0}
                            >
                                Solutions
                            </Text>
                        </NavItemWrapper>
                    </MobileMenuItem>
                    <MobileMenuItem subMenu={<ResourcesMobileMenu onClose={handleDrawerClose} />}>
                        <NavItemWrapper>
                            <Text
                                variant="button"
                                color={`Grey900`}
                                textAlign={`left`}
                                whiteSpace={`nowrap`}
                                lineHeight={0}
                            >
                                Resources
                            </Text>
                        </NavItemWrapper>
                    </MobileMenuItem>
                    <MobileMenuItem removeBorder>
                        <NavItem to={`/about`} text={`About`} onClick={handleDrawerClose} />
                    </MobileMenuItem>
                    <MobileMenuItem removeBorder>
                        <Box
                            display={`flex`}
                            alignItems={`center`}
                            key={8}
                            sx={{
                                padding: '0 8px',
                                width: '100%',
                                height: '48px',
                                '& a': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                            }}
                        >
                            <Button
                                href="/request-a-demo"
                                variant={'contained'}
                                sx={{}}
                                leftIcon={<ArrowCircleRightIcon />}
                                onClick={() => {
                                    /* @ts-ignore: ... */
                                    gtag('event', 'request_demo', {})
                                }}
                            >
                                Request a demo
                            </Button>
                        </Box>
                    </MobileMenuItem>
                    <MobileMenuItem removeBorder>
                        <Box
                            key={9}
                            sx={{
                                width: '100%',
                                justifyContent: 'center',
                                a: {
                                    justifyContent: 'center',
                                },
                            }}
                        >
                            <LoginBtn />
                        </Box>
                    </MobileMenuItem>
                </Drawer>
            )}
        </Box>
    )
}
